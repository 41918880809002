import { SortDirection } from '../components/common/sort/index';
import { SortBy, SortFiltersName } from '../components/mcList/constants';

interface McEventDetails {
    id: string; // event id,
    reg?: string;
    fleet?: string;
    subtype: number;
    eventTitle: string;
    state: string;
    description?: string;
    isHandoverReport?: boolean;
    port?: string; // location for event, port for handover note
    flight?: string; // inbound flight for event, flightNumber for note,
    owner?: {
        id: number;
        displayName: string;
    };
    subtypeStatus?: string;
    startTime?: string;
    recordTime?: string;
    pendingStatusReportCount: number;
}

interface EventListFilters {
    keySearch?: string;
    eventDateType?: string;
    fromDate?: string;
    toDate?: string;
    types?: string[];
    subtypes?: string[];
    statuses?: string[];
    aircraftTypes?: string[];
    aircraftSubtypes?: string[];
    regs?: string[];
    ataChapters?: string[];
    ports?: string[];
    flightNumbers?: string;
    createdBys?: string[];
    closedBys?: string[];
    owners?: number[];
    workRequest?: boolean;
    groupBy?: boolean;
    sorting: string;
    page: number;
    size: number;
}

interface SortObject {
    sortBy: SortBy;
    sortDirection: SortDirection;
}

enum ShiftViewStatusFilter {
    open = 'open',
    closed = 'closed',
}

interface EventListShiftViewFilters {
    status: ShiftViewStatusFilter;
    page: number;
    size: number;
    [SortFiltersName.aogSort]: SortObject;
    [SortFiltersName.nonAogSort]: SortObject;
    [SortFiltersName.allyNonAogSort]: SortObject;
}
interface EventListShiftViewRequestBody
    extends Omit<
        EventListShiftViewFilters,
        SortFiltersName.aogSort | SortFiltersName.nonAogSort | SortFiltersName.allyNonAogSort
    > {
    sorting: string[];
}

interface ShiftViewEventListResponse {
    data: McEventDetails[];
    total: number;
    aogTotal: number;
    nonAogTotal: number;
    allyNonAogTotal: number | null;
}

export {
    McEventDetails,
    EventListFilters,
    EventListShiftViewFilters,
    EventListShiftViewRequestBody,
    SortObject,
    ShiftViewStatusFilter,
    ShiftViewEventListResponse,
};
